import Navbar from "../../Template/Navbar"
import Footer from "../../Template/Footer"
import { Terms } from "./Terms"

const TermsAndConditions = () => {
    return (
        <>
            <Navbar/>
            <Terms/>
            <Footer/>
        </>
    )
}

export default TermsAndConditions
