import React from "react";

export const Refs = {
    emailPhoneNumber: React.createRef(),
    emailMe: React.createRef(),
    country: React.createRef(),
    firstName: React.createRef(),
    lastName: React.createRef(),
    address: React.createRef(),
    apartmentAndOthers: React.createRef(),
    city: React.createRef(),
    state: React.createRef(),
    zip: React.createRef(),
    saveThis: React.createRef(),
    PhoneNumber: React.createRef(),
    billingCountry : React.createRef(),
    billingAddress : React.createRef(),
    billingCity : React.createRef(),
    billingState : React.createRef(),
    billingPin : React.createRef(),
    billingOtherAddress : React.createRef()
};
