import Navbar from "../../Template/Navbar"
import Footer from "../../Template/Footer"
import { Privacy } from "./Privacy"

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <Privacy />
            <Footer />
        </>
    )
}

export default PrivacyPolicy
