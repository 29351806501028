
// Localhost
// export const backendbaseuri = "http://localhost:443/"

//Localhost for Ajinkya
// export const backendbaseuri = "http://localhost:9000/"

//server
export const backendbaseuri = "https://65.2.131.5/"

//phone on same network
// export const backendbaseuri = "http://192.168.0.190:9000/"
