export const Terms = () => {
    return (
        <div className="
            md:px-24
            px-6
        ">
            <div style={{ color: "#c81e55" }} className="my-10 font-bold flex flex-col justify-start items-center
                md:text-5xl md:mx-6
                sm:text-4xl 
                text-3xl 
            ">Terms And Conditions</div>

            <div className="text-lg">
                <div>
                    <div style={{ color: "#c81e55" }} className="font-bold">
                        INTRODUCTION
                    </div>
                    <br />
                    <div className="font-bold" >
                        THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                        <br /><br />

                        THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3(1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT REQUIRES PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF THIS WEBSITE/MOBILE APPLICATION.
                    </div>
                    <br />
                    <div>
                        The domain names www.gynoveda.com and www.gynoveda.in (hereinafter referred to as the “Website”) are owned by Gynoveda Femtech Private Limited, a company incorporated under the Companies Act, 2013, and having its registered office at 411, Banarsi Heritage Condominium, Mindspace, Malad West, Mumbai - 400064 (“Gynoveda”).The Website is a platform for selling Ayurveda based products relating to women health, wellness, gynaecological products & services for menstrual health (“Products”) to customers primarily pan India. Gynoveda, may, at its sole discretion and in a manner it deems fit service customers outside India on a case to case basis.
                        <br /> <br />
                        Gynoveda reserves a right, at its sole discretion, to change, modify, add or remove all or any of these Terms of Use (“Terms” or “Terms of Use”), at any time without any prior written notice to you. It is your sole responsibility to review these Terms periodically for updates and changes. Your continued use of the Website following the posting of changes shall be deemed to mean that you accept and agree to the revisions. As long as you comply with these Terms, Gynoveda grants you a personal, non-exclusive, non-transferable, limited privilege to access and use the Website.
                    </div>
                </div>
                <br />
                <div>
                    <div style={{ color: "#c81e55" }} className="font-bold">
                        Acceptance of Terms
                    </div>
                    <br />
                    <div>
                        <>
                            <li>
                                Please carefully read these Terms. In order to use the Website, you must first agree to the Terms. By accessing, browsing or otherwise using the Website, you are agreeing to these Terms and concluding a legally binding contract with Gynoveda. You are advised to read these Terms carefully before proceeding.
                            </li>
                            <li>
                                You may not use the Website if you do not accept the Terms or are unable to be legally bound by the Terms. Your use of the Website is governed by these Terms and shall include the applicable policies which are incorporated herein by way of reference. By impliedly or expressly accepting these Terms, you also accept and agree to be bound by all policies of Gynoveda, including but not limited to its privacy policy ("Privacy Policy"), as amended from time to time.
                            </li>
                            <li>
                                As a condition of your access to and use of the Website, you agree that you will comply with all applicable laws and regulations when using the Website.
                            </li>
                        </>

                    </div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Membership Eligibility</div>
                    <div>
                        <br />
                        Use of the Website is available only to persons who can enter into legally binding contracts under the Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents and persons of unsound mind are not eligible to use the Website. Any person under the age of 18 shall not register on the Website and shall not transact on or use the Website. If a minor wishes to use or transact on the Website, such use or transaction may be made by the minor’s legal guardian or parents on the Website. Gynoveda reserves the right to terminate any person’s membership and/or refuse to provide such person with access to the Website if it is brought to Gynoveda’s notice or if it is discovered that such person is not eligible to use the Website.
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Account and Registration Obligations
                    </div>
                    <br />
                    <div>
                        <>
                            <li>
                                The Website allows only limited and restricted access to the services for unregistered users.
                            </li>
                            <li>
                                In order to use the Website, as part of the registration process, you will have to create an account on the Website (“Account”), which can be done either by signing up through Facebook or Google or by entering your name, e-mail address and password.
                            </li>
                            <li>
                                While registering with Gynoveda to use the Website, you shall not:
                                <div className="pl-12">
                                    <li>
                                        create an Account for anyone other than yourself, unless such person's prior permission has been obtained;
                                    </li>
                                    <li>
                                        use an Account that is the name of another person with the intent to impersonate that person;
                                    </li>
                                    <li>
                                        use a name for the Account that is a name that is otherwise offensive, vulgar or obscene or otherwise unlawful; or
                                    </li>
                                    <li>
                                        create more than one Account on the Website.
                                    </li>
                                </div>
                                <br />
                                Gynoveda cannot and will not be liable for any loss or damage arising from your failure to comply with this Clause.
                            </li>
                            <li>
                                Once registered, in order to log into the Account on the Website, you will be required to provide the e-mail address, mobile number and password used at the time of registration (“Account Information”).
                            </li>
                            <li>
                                You agree that the information that you provide to us at all times, including at the time of registration, will be true, accurate, up-to-date, and complete. You agree that if you provide any information that is untrue, inaccurate, not up-to-date or incomplete or becomes untrue, inaccurate, not up-to-date or incomplete or if Gynoveda has reasonable grounds to suspect that such information is untrue, inaccurate, not up-to-date, incomplete, or not in accordance with the these Terms, Gynoveda shall have the right to indefinitely suspend or terminate or block access to your Account on the Website and refuse to provide you access to the Website.
                            </li>
                            <li>
                                You shall not transfer or share your Account Information with anyone. You are responsible for maintaining the confidentiality of your Account Information and for all activities that occur under your Account.
                            </li>
                            <li>
                                Gynoveda reserves the right to take any and all action, as it deems necessary or reasonable, regarding the security of the Website and your Account Information.
                            </li>
                            <li>
                                In no event and under no circumstances shall Gynoveda be held liable to you for any liabilities or damages resulting from or arising out of your use of the Website, theft of your Account Information, your use of the Account Information or your release of the Account Information to a third party or your authorization to allow another person to access and use the Website using your Account.
                            </li>
                        </>
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Services Offered on the Website
                    </div>
                    <br />
                    <div>
                        <li>
                            Gynoveda, through the Website, simply acts as a platform for selling the Products offered to you on the Website. Gynoveda does not have any control and does not determine or advise or in any way involve itself in the manufacturing of the Products. It is further clarified that Gynoveda only sources such Products from Dr. Aarti Patil and/or from other third-party manufacturers (as the case may be) from time to time. Under no circumstance does Gynoveda claim to manufacture the Products offered for sale on the Website or provide any warranty in relation to such Products, and any claims in relation to the unsatisfactory performance of the Products or the like should be channelled to Dr. Aarti Patil and/or such third party manufacturers (as the case may be), whose details Gynoveda may provide to you as it may deem fit.
                        </li>
                        <li>
                            Gynoveda provides a number of services through the Website, as enumerated in detail below.
                        </li>
                        <li>
                            Purchase of Products
                            <div className="pl-12">
                                <li>
                                    This service enables you to purchase Products from Gynoveda directly through the Website from time to time. It shall be your sole responsibility to ensure that the Products so purchased are personally consumed by you, and not by any third party, except in cases of purchases made, on behalf of a minor, by the minor’s legal guardian or parents on the Website. It is clarified that Products may not be purchased by any entity, not being a natural person, under any circumstance whatsoever. Gynoveda reserves the right to terminate any person’s membership and/or refuse to provide such person with access to the Website and/or cancel any orders so placed, if it is brought to Gynoveda’s notice or if it is discovered that such person is not eligible to purchase the Products in accordance with this Clause. You hereby agree that Gynoveda shall in such a scenario have the right to cancel your order placed and refund the payment made by you at the time of purchase of the Product in accordance with the refund policy, at its sole discretion. Gynoveda cannot and will not be liable for any loss or damage arising from your failure to comply with this Clause.
                                </li>
                                <li>
                                    The Products can be purchased on the Website through various methods of payments offered. The sale/purchase of Products shall be additionally governed by the returns/exchange policy, refund policy and cancellation policy, as contained in these Terms.
                                </li>
                                <li>
                                    To help you understand which Products to purchase from Gynoveda on the Website, you may fill a questionnaire called the ‘Period Test’ on the Website (“Questionnaire”). Upon completion of the Questionnaire, you acknowledge and understand that based on your responses to the Questionnaire, Gynoveda will provide the results of your Questionnaire and also recommend certain Products to purchase on the Website. As part of this service, Gynoveda also enables you to chat with a health practitioner or doctor (recommended by Gynoveda)to further understand the results of the Questionnaire.
                                </li>
                            </div>
                        </li>
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Use of the Website
                    </div>
                    <br />
                    <li>
                        You agree, undertake and covenant that, during the use of the Website, you shall not host, display, upload, modify, publish, transmit, update or share any information that:
                        <div className="pl-12">
                            <li>
                                belongs to another person or entity and to which you do not have any right.
                            </li>
                            <li>
                                is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another person's privacy, hateful or racially or ethnically objectionable, or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever.
                            </li>
                            <li>
                                is misleading in any way.
                            </li>
                            <li>
                                involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming".
                            </li>
                            <li>
                                infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity.
                            </li>
                            <li>
                                provides instructional information about illegal activities such as violating someone's privacy, or providing or creating computer viruses.
                            </li>
                        </div>
                    </li>
                    <li>
                        You agree and acknowledge that you shall not use the Website for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations applicable to your use of the Website. You further agree and acknowledge that you shall use the Products and/or any other services (including the Monthly Subscription Service) purchased on the Website only for your personal use and not for business purposes.
                    </li>
                    <li>
                        Gynoveda shall have the right, but not the obligation, to monitor access to or use of the Website to ensure your compliance with these Terms or applicable laws or other legal requirements, at its sole discretion.
                    </li>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Your Reviews
                    </div>
                    <br />
                    <div>
                        <li>
                            belongs to another person or entity and to which you do not have any right.
                        </li>
                        <li>
                            is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another person's privacy, hateful or racially or ethnically objectionable, or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever.
                        </li>
                        <li>
                            is misleading in any way.
                        </li>
                        <li>
                            involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming".
                        </li>
                        <li>
                            infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity.
                        </li>
                        <li>
                            provides instructional information about illegal activities such as violating someone's privacy, or providing or creating computer viruses.
                        </li>
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Payment Facility
                    </div>
                    <br />
                    <div>
                        <li>
                            Gynoveda, through the Website, simply acts as a platform for selling the Products offered to you on the Website. Gynoveda does not have any control and does not determine or advise or in any way involve itself in the manufacturing of the Products. It is further clarified that Gynoveda only sources such Products from Dr. Aarti Patil and/or from other third-party manufacturers (as the case may be) from time to time. Under no circumstance does Gynoveda claim to manufacture the Products offered for sale on the Website or provide any warranty in relation to such Products, and any claims in relation to the unsatisfactory performance of the Products or the like should be channelled to Dr. Aarti Patil and/or such third party manufacturers (as the case may be), whose details Gynoveda may provide to you as it may deem fit.
                        </li>
                        <li>
                            Gynoveda provides a number of services through the Website, as enumerated in detail below.
                        </li>
                        <li>
                            Purchase of Products
                            <div className="pl-12">
                                <li>
                                    This service enables you to purchase Products from Gynoveda directly through the Website from time to time. It shall be your sole responsibility to ensure that the Products so purchased are personally consumed by you, and not by any third party, except in cases of purchases made, on behalf of a minor, by the minor’s legal guardian or parents on the Website. It is clarified that Products may not be purchased by any entity, not being a natural person, under any circumstance whatsoever. Gynoveda reserves the right to terminate any person’s membership and/or refuse to provide such person with access to the Website and/or cancel any orders so placed, if it is brought to Gynoveda’s notice or if it is discovered that such person is not eligible to purchase the Products in accordance with this Clause. You hereby agree that Gynoveda shall in such a scenario have the right to cancel your order placed and refund the payment made by you at the time of purchase of the Product in accordance with the refund policy, at its sole discretion. Gynoveda cannot and will not be liable for any loss or damage arising from your failure to comply with this Clause.
                                </li>
                                <li>
                                    The Products can be purchased on the Website through various methods of payments offered. The sale/purchase of Products shall be additionally governed by the returns/exchange policy, refund policy and cancellation policy, as contained in these Terms.
                                </li>
                                <li>
                                    To help you understand which Products to purchase from Gynoveda on the Website, you may fill a questionnaire called the ‘Period Test’ on the Website (“Questionnaire”). Upon completion of the Questionnaire, you acknowledge and understand that based on your responses to the Questionnaire, Gynoveda will provide the results of your Questionnaire and also recommend certain Products to purchase on the Website. As part of this service, Gynoveda also enables you to chat with a health practitioner or doctor (recommended by Gynoveda)to further understand the results of the Questionnaire.
                                </li>
                            </div>
                        </li>
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Shipping
                    </div>
                    <br />
                    <div>
                        <li>
                            belongs to another person or entity and to which you do not have any right.
                        </li>
                        <li>
                            is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another person's privacy, hateful or racially or ethnically objectionable, or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever.
                        </li>
                        <li>
                            is misleading in any way.
                        </li>
                        <li>
                            involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming".
                        </li>
                        <li>
                            infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity.
                        </li>
                        <li>
                            provides instructional information about illegal activities such as violating someone's privacy, or providing or creating computer viruses.
                        </li>
                    </div>
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Returns/Exchange Policy
                    </div>
                    <br />


                    Subject to Clause 9.8 below, Gynoveda shall accept only the following unused Products for returns/exchange:
                    Physically damaged Product;
                    Where the complete Product has not been delivered;
                    Where the Product is different from description on the Website;
                    Wrong quantity received.
                    You must notify Gynoveda in writing within 5 (Five) business days from the date of receipt of the Product and must not consume the Product for being eligible for the return/exchange.
                    In the event that no such written notification is received by Gynoveda within the time period mentioned above and/or the Product is consumed and/or if the Product was purchased using the cash on delivery (COD) option, Gynoveda shall not be required to accept the return/exchange of the Product. It is clarified that if an order is placed on the Website using the cash on delivery (COD) option, Gynoveda reserves the right to accept your return/exchange requests only in exceptional cases.
                    Upon receipt of a return request from you within the period mentioned in Clause 9.2 above, Gynoveda shall collect the Product from you through its logistics partner at its cost and inspect the product as provided in Clause 9.4 below.
                    Upon receiving the Product sought to be returned/exchanged by you, Gynoveda shall be entitled to inspect whether there is in fact any defect or damage in the Product (as per Clause 9.1 above) or if the Product has been consumed by you entitling you to return the Product or ask for a replacement.
                    Upon inspection of the Product as provided in Clause 9.4 above, if Gynoveda is of the opinion that the returned Product is not defective/damaged/not as per the order placed and/or the Product has been consumed then you will be informed about the same and the same Product will be reshipped to your shipping address. You will have to bear the shipment charges for the reshipped Product.
                    In the event, where after the inspection of the returned Product Gynoveda finds that the Product is defective/damaged/not as per the order placed and/or the Product has not been consumed, and accepts your return/exchange claim, Gynoveda shall (i) in case of an exchange request placed, provide you with a replacement product within 15 (Fifteen) business days of receipt of request; or (ii) in case of a return request placed, refund the payment made by you at the time of purchase of the Product in accordance with the refund policy.
                    In the event that you choose not to fill the Questionnaire and/or fix a consultation appointment with the health practitioner or doctor (recommended by Gynoveda) and have written to us at care@gynoveda.com to claim refund for the order so placed in accordance with these Terms, you must immediately return the unused Products in the manner indicated by Gynoveda. In the event, where after the inspection of the returned Product Gynoveda finds that the Product has not been consumed, Gynoveda shall refund the payment made by you at the time of purchase of the Product in accordance with the refund policy. In the event that no such written notification is received by Gynoveda and/or the Product is consumed and/or the Product is not returned in the manner indicated by Gynoveda, Gynoveda shall not be required to accept the return of the Product.
                    All decisions in relation to the returns/exchange requests made by Gynoveda shall be final and binding. You agree not to challenge such decision in any manner whatsoever.
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Refund Policy
                    </div>
                    <br />


                    Only those Products for which (i) a return/exchange request has been placed by you as per Gynoveda’s returns/exchange policy and for which (a) the replacement is not possible; or (b) a replacement is not asked for; or (ii) a cancellation request has been initiated by Gynoveda (whether in relation to a specific order placed or the Monthly Subscription Service) or has been placed by you as per the cancellation policy; or (iii) a refund request has been placed in accordance with Clause 9.8 above, you will be eligible for a refund. Details in relation to such refunds shall be informed to you through your registered e-mail. Gynoveda reserves the right to honour such refund requests in a manner it deems fit and it is clarified that such refund requests shall be accepted by Gynoveda only in exceptional cases. You agree not to challenge such decision in any manner whatsoever.
                    Online Refunds
                    In case the payment mode of the returned or cancelled order is online (credit card/debit card/net banking/virtual payment wallets) the transaction amount will be refunded to your bank account or the virtual payment wallets, as may be applicable.
                    Refund will be done within 5 (Five) business days from the date of request of return/cancellation (other than for refund requests placed in accordance with Clause 9.8 above), if found eligible.
                    Refund will be done within 45 (Forty Five) business days from the date of refund request in accordance with Clause 9.8 above.
                    Offline Refunds
                    In case the payment mode of the returned order is cash on delivery (COD), the transaction amount will be, at Gynoveda’s option, provided to you as credit to purchase any other Products on the Website or cash vouchers that can be redeemed elsewhere with other websites, as may be indicated by Gynoveda.
                    Provision of such credit or cash vouchers, as Gynoveda may deem fit, will be done within 5 (Five) business days from the date of request of return/cancellation (other than for refund requests placed in accordance with Clause 9.8 above), if found eligible.
                    Provision of such credit or cash vouchers, as Gynoveda may deem fit, will be done within 45 (Forty Five) business days from the date of refund requests placed in accordance with Clause 9.8 above.
                    Whilst describing the Products available for purchase on the Website, Gynoveda has taken all care and precaution to be as accurate as possible. However, Gynoveda does not warrant that product descriptions or other content on the Website is accurate, complete, reliable, current, or error-free. In the event that a Product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, Gynoveda may, at its discretion, either contact you for instructions or cancel the order and notify you of such cancellation. In such cases Gynoveda will refund the transaction amount to you (only for which the payment is already received) in a manner it deems fit.
                </div>

                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Cancellation Policy
                    </div>
                    <br />


                    Cancellation by Gynoveda
                    If you place an order and due to some reason (unexpected lack of inventory or any other reason) Gynoveda is not able to ship the Product, the order will be cancelled and the transaction amount, if already paid, will be refunded to you as per the refund policy.
                    There may be certain orders and/or subscription service that Gynoveda may be unable to accept and must cancel, including but not limited to, instances where that the health practitioner or doctor (recommended by Gynoveda) is of the opinion that the Product/s so ordered are not suited to your menstrual health needs (based on your responses to the Questionnaire). Gynoveda reserves the right, to refuse or cancel any order and/or subscription service for any reason whatsoever at its sole discretion.
                    Some situations that may result in your order being cancelled include, without limitation, non-availability of the Products or quantities of Products ordered by you, non-availability of the services on the Website, inaccuracies or errors in pricing information, or due to a Force Majeure Event.
                    Gynoveda may also require additional verifications or information before accepting any order. Gynoveda will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after you have been charged, the transaction amount will be refunded to you as per the refund policy.
                    A “Force Majeure Event” for the purpose of these Terms shall mean any event that is beyond the reasonable control of Gynoveda and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, civil disturbances, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, pandemic or national/state lockdown due to any reason and any other similar events not within the control of Gynoveda and which Gynoveda is not able to overcome.
                    Cancellation by You
                    In case of requests for order cancellations, Gynoveda reserves the right to accept or reject requests for order cancellations for any reason whatsoever. As part of usual business practice, if Gynoveda receives a cancellation notice and the order has not been processed/approved or dispatched by Gynoveda, Gynoveda shall cancel the order and refund the transaction amount (if already paid) to you as per the refund policy. Gynoveda will not be able to cancel orders that have already been processed or have been dispatched. Gynoveda has the full right to decide whether an order has been processed/dispatched or not. You agree not to dispute the decision made by Gynoveda and accept Gynoveda’s decision regarding the cancellation.


                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Links to Third Party Websites
                    </div>
                    <br />


                    The Website may contain links and interactive functionality interacting with the websites of third parties. Gynoveda is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such website. Before enabling any sharing functions to communicate with any such website or otherwise visiting any such website, Gynoveda strongly recommends that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.

                    Upon an order being placed on the Website, Gynoveda may, at its sole discretion, also offer coupons or such other deals to you to purchase products from third-party websites. Gynoveda is not responsible for and has no liability for actions/inactions of any such third parties in relation to the coupons or such other deals offered by Gynoveda. These coupons or such other deals offered by Gynoveda on the Website are for the sole purpose of your personal use and not for the purpose of further re-sale or distribution. Gynoveda reserves the right to revoke any coupons or such other deals at any time as it may deem fit.

                    For Products purchased from third-party websites, Gynoveda strongly recommends that you review and understand the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party websites.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Representations
                    </div>
                    <br />


                    You hereby represent and warrant that you have validly entered into these Terms and have the legal power to do so. You further represent and warrant that you shall be solely responsible for the due compliance with these Terms.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Limitation of Liability
                    </div>
                    <br />


                    IN NO EVENT SHALL GYNOVEDA, ITS DIRECTORS, EMPLOYEES OR AGENTS, BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE; (III) UNAUTHORIZED ACCESS TO OR USE OF THE SECURE SERVERS AND/OR ANY AND ALL PERSONAL AND/OR FINANCIAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE; (V) BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY; AND/OR (VI) ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT GYNOVEDA IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUBJECT TO THE ABOVE AND NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, THE MAXIMUM AGGREGATE LIABILITY (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR EQUITY) OF GYNOVEDA VIS-À-VIS ANY USER OF THE WEBSITE, REGARDLESS OF THE FORM OF CLAIM, SHALL BE LIMITED TO THE AGGREGATE OF TRANSACTION AMOUNT PAID BY THE USER TO GYNOVEDA. THE FOREGOING LIMITATIONS OF LIABILITY WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY HEREIN.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Indemnification
                    </div>
                    <br />


                    You shall indemnify and hold harmless Gynoveda, its licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any and all claims or demands, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms, Privacy Policy and other policies, or your violation of any law, rules or regulations or the rights (including infringement of any intellectual property rights) of a third party.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Intellectual Property Rights
                    </div>
                    <br />


                    The Website and the processes, and their selection and arrangement, including but not limited to all text, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (collectively, the “Content”)on the Website is owned and controlled by Gynoveda or its manufacturers or suppliers and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.
                    Through your use of the Website, by no means are any rights impliedly or expressly granted to you in respect of such Content. Gynoveda reserves the right to change or modify the Content from time to time at its sole discretion.
                    The trademarks, logos and service marks displayed on the Website ("Marks") are the property of Gynoveda or its vendors or manufacturers or respective third parties. You are not permitted to use the Marks without the prior consent of Gynoveda, the vendor or the manufacturer or the third party that may own the Marks.
                    Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Gynoveda owns all intellectual property rights to and into the trademark “Gynoveda”, and the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.
                    Except as expressly provided herein, you acknowledge and agree that you shall not copy, republish, post, display, translate, transmit, reproduce or distribute any Content through any medium without obtaining the necessary authorization from Gynoveda or thirty party owner of such Content. You may download material from the Website only for your own personal use and for no commercial purposes whatsoever.
                    Any infringement shall lead to appropriate legal proceedings against you at appropriate forum for seeking all available remedies under applicable laws of India.</div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Termination of Access to your Account
                    </div>
                    <br />


                    The Account can be terminated at any time by:
                    You; by ceasing to use the Website.
                    Gynoveda; in its sole discretion for any reason or no reason including your violation of these Terms or lack of use of the Website. You acknowledge that the termination of services may be affected without any prior notice, and Gynoveda may immediately deactivate or delete your Account and all related information and/or bar any further access to your Account or the Website. Further, you agree that Gynoveda shall not be liable for any discontinuation or termination of services by any third party.
                    The disclaimer of warranties, the limitation of liability, and the governing law provisions shall survive any termination of these Terms.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Privacy Policy
                    </div>
                    <br />


                    As elaborated under the Privacy Policy, Gynoveda will keep all confidential information confidential and shall not disclose it to anyone except as required by law, and shall ensure that such confidential information is protected with security measures and a degree of care that it would apply to its own confidential information. Gynoveda acknowledges that its employees, directors, agents and contractors shall use the confidential information only for the intended purpose for which it is provided. Gynoveda shall use all reasonable endeavours to ensure that its employees, directors, agents and contractors acknowledge and comply with the provisions of these terms of confidentiality as if such person was subject to these terms of confidentiality. If you object to your information being transferred or used, please do not use the Website.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Governing Law and Dispute Resolution
                    </div>
                    <br />


                    These Terms shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall exclusively be in Mumbai, Maharashtra, India. In the event of any dispute arising out of these Terms the same shall be settled by a binding arbitration conducted by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and Conciliation Act, 1996. The venue of arbitration shall be Mumbai, Maharashtra, India.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Assignment
                    </div>
                    <br />


                    It is expressly agreed by the parties that Gynoveda may assign all or part of its rights or duties under these Terms without such assignment being considered a change to these Terms and without notice to you, except to the extent provided by law.
                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        Grievance Redressal Mechanism
                    </div>
                    <br />


                    In accordance with the Information Technology Act, 2000 and Rules made thereunder, the name and contact details of the Grievance Officer are provided below:

                    Name: The Grievance Officer of Gynoveda FemTech Private Limited

                    Address: Gynoveda, Wing A, Level 9, Eureka Tower, Mindspace, Malad West, Mumbai, Maharashtra 400064

                    Ph. No.: +919920774720

                    E-mail: terms@gynoveda.in

                    If you wish to make a complaint regarding any violation of the provisions of these Terms, you may send a written complaint to the Grievance Officer, who shall redress the complaint in accordance with the provisions of the Information Technology Act, 2000 and Rules made thereunder.


                </div>
                <div>
                    <br />
                    <div style={{ color: "#c81e55" }} className="font-bold" >
                        How to Contact Us
                    </div>
                    <br />


                    If you have questions or concerns about these Terms, please contact Gynoveda at terms@gynoveda.in.
                </div>
            </div>

           
        </div>
    )
}

